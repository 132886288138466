import React from "react"
import { Link } from "gatsby"
import './contact.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHero from '../components/pageHero'
import phone from '../images/phone.png'
import location from '../images/location-pin.png'
import email from '../images/email.svg'
import contactHero from '../images/contact-hero.png'

const TeamPage = () => (
  <Layout>
    <SEO title="Libation Ltd Contact Information" />
    <PageHero {...{title: "Contact Us", image: contactHero}}/>
    <div className="page-layout flex">
      <div className="contact-vitals flex-stack flex1">
        <div className="flex contact-wrapper">
          <div className="contact-icon" style={{backgroundImage: `url(${location})`}}></div>
          <div className="flex-stack flex1 address">
            <p>215 Fort York, Suite 405</p>
            <p>Toronto, ON, Canada</p>
          </div>
        </div>

        <div className="flex contact-wrapper">
          <div className="contact-icon" style={{backgroundImage: `url(${phone})`}}></div>
          <div className="flex-stack flex1 address">
            <p>917-510-4577</p>
            <p>416-428-9666</p>
          </div>
        </div>

        <div className="flex contact-wrapper">
          <div className="contact-icon" style={{backgroundImage: `url(${email})`}}></div>
          <div className="flex-stack flex1 address">
            <p>info@libationltd.com</p>
          </div>
        </div>



      </div>

    </div>
  </Layout>
)

export default TeamPage
